











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { retrievePrivacyPolicy, getPrivacyPolicyHtml } from '@/api/privacy-policy'
import sanitizeHTML from 'sanitize-html'

@Component({
  name: 'PrivacyPolicyPage',
})
export default class PrivacyPolicyPage extends Vue {
  @Prop({ default : ''}) private medicalBusinessId! :string
  private content = ''

  @Watch('medicalBusinessId', { immediate: true })
  private async onMedicalBusinessIdChange() {
    if (!this.medicalBusinessId) return
    const privacyPolicy = await retrievePrivacyPolicy(this.medicalBusinessId)
    const contentUrl = privacyPolicy.file_url ? privacyPolicy.file_url : privacyPolicy.default_file_url
    const result = await getPrivacyPolicyHtml(contentUrl)
    this.content = result || ''
  }

  private get sanitizeText() {
    return sanitizeHTML(this.content)
  }
}
