import request from '@/utils/request'
import axios from 'axios'
import { MedicalBusinessPrivacyPolicy } from '@/interface/api'

const prefix = '/api/v0/customer/medical-businesses/privacy-policy'

export const retrievePrivacyPolicy = (medicalBusinessId: string): Promise<MedicalBusinessPrivacyPolicy> =>
  request({
    url: `${prefix}/${medicalBusinessId}/`,
    method: 'get'
  }).then(response => response.data.data.attributes)

export const getPrivacyPolicyHtml = (url: string): Promise<string> =>
  axios.get(url).then(response => response.data).catch(() => false)
